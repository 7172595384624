import Layout from '@/components/Layout'
import { RouterConfig } from '@/components/Router'
// import { Navigate } from 'react-router'
// react-router v6 https://reactrouter.com/en/main/start/overview

// 不需要layout 的页面，放在前面即可优先匹配
const noLayoutConfig: RouterConfig = []
// 需要放在layout 内的页面
export const layoutConfig: RouterConfig = [
  {
    title: '礼记',
    index: true,
    element: () => import('@/pages/home/index'),
  },
  {
    title: '礼记',
    path: 'order',
    element: () => import('@/pages/order/list/index'),
  },

  {
    title: '礼记用户协议',
    path: 'userAgreement',
    element: () => import('@/pages/userAgreement/index'),
  },
  {
    title: '隐私政策',
    path: 'privacyAgreement',
    element: () => import('@/pages/privacyAgreement/index'),
  },
  {
    title: '商品详情',
    path: 'productDetail/:productId',
    element: () => import('@/pages/productDetail/index'),
  },
  {
    title: '商户入住',
    path: 'merchantInterpage',
    element: () => import('@/pages/merchantInterpage/index'),
  },
  // {
  //   title: '我的订单',
  //   path: 'order',
  //   menu: true,
  //   element: () => import('@/pages/other/layout'),
  //   children: [
  //     {
  //       title: 'index',
  //       path: 'index',
  //       menu: true,
  //       element: () => import('@/pages/other/index'),
  //     },
  //     {
  //       title: 'index-detail',
  //       path: 'index/detail',
  //       element: () => import('@/pages/other/index/detail'),
  //     },
  //     {
  //       index: true,
  //       title: 'second',
  //       path: 'second',
  //       menu: true,
  //       element: () => import('@/pages/other/second'),
  //     },
  //     {
  //       path: '',
  //       element: <Navigate to={'index'} />,
  //     },
  //   ],
  // },
  {
    path: '/404',
    element: () => import('@/pages/error/404'),
  },
  {
    path: '/401',
    element: () => import('@/pages/error/401'),
  },
]
export default noLayoutConfig.concat({
  path: '/',
  element: <Layout />,
  menu: true,
  children: layoutConfig,
})
