import { makeAutoObservable } from 'mobx'
import { MessageArgsProps } from 'antd'
export type JointContent = string | MessageArgsProps

export type TypeOpen = (
  content: JointContent,
  duration?: number, // Also can use onClose directly
  onClose?: VoidFunction,
) => void

class Message {
  messageType: 'open' | 'warning' | 'loading' | 'info' | 'error' | 'success' | '' = ''
  messageContent: MessageArgsProps | null = null
  constructor() {
    makeAutoObservable(this)
  }
  error: TypeOpen = (content, duration) => {
    const messageContent: MessageArgsProps = typeof content === 'string' ? { content } : content
    if (duration !== undefined) {
      messageContent.duration = duration
    }
    this.messageContent = messageContent
    this.messageType = 'error'
  }
  success: TypeOpen = (content, duration) => {
    const messageContent: MessageArgsProps = typeof content === 'string' ? { content } : content
    if (duration !== undefined) {
      messageContent.duration = duration
    }
    this.messageContent = messageContent
    this.messageType = 'success'
  }
  info: TypeOpen = (content, duration) => {
    const messageContent: MessageArgsProps = typeof content === 'string' ? { content } : content
    if (duration !== undefined) {
      messageContent.duration = duration
    }
    this.messageContent = messageContent
    this.messageType = 'info'
  }
  warning: TypeOpen = (content, duration) => {
    const messageContent: MessageArgsProps = typeof content === 'string' ? { content } : content
    if (duration !== undefined) {
      messageContent.duration = duration
    }
    this.messageContent = messageContent
    this.messageType = 'warning'
  }
  loading: TypeOpen = (content, duration) => {
    const messageContent: MessageArgsProps = typeof content === 'string' ? { content } : content
    if (duration !== undefined) {
      messageContent.duration = duration
    }
    this.messageContent = messageContent
    this.messageType = 'loading'
  }
  open = (content: MessageArgsProps) => {
    this.messageContent = content
    this.messageType = 'open'
  }
}

export default class AntdBus {
  message: Message = new Message()
  constructor() {
    makeAutoObservable(this)
  }
}
