import React, { useMemo, useRef, useState } from 'react'
import { Button, Radio, Form, Input, App, FormInstance } from 'antd'
import styles from './index.module.scss'
import { useRootStore, phoneReg } from '@/store'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router'

export interface FormData {
  mobile: string
  code: string
}

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo)
}

interface FieldType {
  phone: string
  smsCode: string
  agreement: boolean
}

interface Props {
  form: FormInstance
  onSuccess: () => void
  submitText: React.ReactNode
}
const LoginForm: React.FC<Props> = observer(({ form, onSuccess, submitText }) => {
  const { message } = App.useApp()
  const navigate = useNavigate()
  const { loginStore } = useRootStore()
  const [num, setNum] = useState(0)
  const sedSmsFlage = useRef<boolean>(false)

  const sendSmsAcitve = useMemo(() => {
    return num === 0
  }, [num])
  const onFinish = (values: any) => {
    if (!sedSmsFlage.current) {
      message.error('请先获取验证码')
      return
    }
    if (!values.agreement) {
      message.error('请阅读并同意《礼记用户协议》《隐私政策》')
      return
    }
    loginStore.login(values).then((res) => {
      if (res.code === 0) {
        form.resetFields(['smsCode'])
        onSuccess()
      }
    })
  }
  const getSmsCode = () => {
    if (sendSmsAcitve) {
      form.validateFields(['phone']).then(({ phone }) => {
        loginStore.senMsg(phone).then((response) => {
          const res = response.data
          if (res.code === 0) {
            setNum(61)
            startCutDown(61)
            sedSmsFlage.current = true
            message.success('验证码发送成功')
          }
        })
      })
    }
  }
  const startCutDown = (num: number) => {
    if (num > 0) {
      setNum(num - 1)
      setTimeout(() => startCutDown(num - 1), 1000)
    }
  }
  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 0 }}
      wrapperCol={{ span: 24 }}
      initialValues={{}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item<FieldType>
        label=""
        name="phone"
        rules={[
          { required: true, message: '请输入手机号' },
          { pattern: phoneReg, message: '手机号格式不正确' },
        ]}
      >
        <Input size={'large'} className={styles.input} maxLength={11} />
      </Form.Item>

      <Form.Item<FieldType>
        label=""
        name="smsCode"
        rules={[
          { required: true, message: '请输入验证码' },
          {
            pattern: /^\d{6}$/,
            message: '验证码错误',
          },
        ]}
      >
        <Input
          size={'large'}
          maxLength={6}
          className={`${styles['input-code']} ${styles.input} ${
            sendSmsAcitve ? 'active' : 'dsiable'
          }`}
          addonAfter={
            <div onClick={getSmsCode}>
              {sendSmsAcitve ? '' : `${num}s`}
              获取验证码
            </div>
          }
        />
      </Form.Item>

      <Form.Item<FieldType> name="agreement" valuePropName="checked">
        <Radio>
          <div className={styles['agreement-text']}>
            我己阅读并同意
            <span
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                navigate('/userAgreement')
                loginStore.open(false)
              }}
            >
              《礼记用户协议》
            </span>
            <span
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                navigate('/privacyAgreement')
                loginStore.open(false)
              }}
            >
              《隐私政策》
            </span>
          </div>
        </Radio>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" className={styles.submit}>
          {submitText}
        </Button>
      </Form.Item>
    </Form>
  )
})

export default LoginForm
