import React, { useEffect, useState } from 'react'
import { RouteObject } from 'react-router'
import { RouteErrorBoundary } from './RouteErrorBoundary'
import { createBrowserRouter, RouterProvider, LoaderFunctionArgs } from 'react-router-dom'
import Loading from './PageLoading'

export type AsyncRoute = () => Promise<any>
export type SyncComponent = React.ComponentClass<any> | React.FunctionComponent
export type AsyncElement = (() => Promise<any>) | React.ReactNode

export interface MenuConfig {
  title: string
}
export interface CustomRouterItem {
  menu?: boolean | MenuConfig // 菜单相关配置
  title?: string // 页面标题
  element?: AsyncElement
  children?: RouterItem[]
}

export type RouterItem = Omit<RouteObject, 'element' | 'children'> & CustomRouterItem
export type RouterConfig = RouterItem[]

export interface RouterProps {
  routerConfig: RouterConfig
}

const Router: React.FC<RouterProps> = ({ routerConfig }) => {
  const [router, setRouter] = useState<any>()
  useEffect(() => {
    const loop = (config: RouterConfig) => {
      return config.map((item) => {
        const { element, children = [], errorElement = <RouteErrorBoundary /> } = item
        const result: RouteObject = {
          ...item,
          errorElement,
          loader: (args: LoaderFunctionArgs) => {
            // 设置页面标题
            if (item.title) {
              document.title = item.title
            }
            return item.loader ? item.loader(args) : undefined
          },
        } as unknown as RouteObject
        if (element) {
          if (typeof element === 'function') {
            let Com: any = element
            Com = React.lazy(element as () => Promise<any>)
            result.element = (
              <React.Suspense fallback={<Loading />}>
                <Com></Com>
              </React.Suspense>
            )
          } else {
            result.element = element
          }
        }
        if (children.length > 0) {
          result.children = loop(children)
        }
        return result
      })
    }
    const _router = createBrowserRouter(loop(routerConfig))
    setRouter(_router)
    return () => {
      _router.dispose()
    }
  }, [routerConfig])
  return router ? <RouterProvider router={router} /> : null
}

export default React.memo(Router)
