import { makeAutoObservable, runInAction } from 'mobx'
import request from '@/utils/request'
import * as storage from '@/utils/storage'

export const TOKEN_KEY = 'TOKEN_KEY'
export const USER_KEY = 'USER_KEY'

export function getToken() {
  return storage.getItem(TOKEN_KEY)
}
export function setToken(token: string) {
  return storage.setItem(TOKEN_KEY, token)
}
export function getUser() {
  return storage.getItem(USER_KEY)
}
export function setUser(user: User | null) {
  return storage.setItem(USER_KEY, user)
}

export enum ActiveKey {
  'login' = 'login',
  'register' = 'register',
}

interface User {
  phone: string
  token: string
  userId: number
}
export default class LoginStore {
  token = getToken()
  user: User | null = getUser()
  loginOpen: boolean = false
  activeKey: ActiveKey = ActiveKey.login
  constructor() {
    makeAutoObservable(this)
  }
  open = (loginOpen: boolean, activeKey?: ActiveKey) => {
    this.loginOpen = loginOpen
    this.activeKey = activeKey ?? ActiveKey.login
  }
  updateActiveKey = (activeKey: ActiveKey) => {
    this.activeKey = activeKey
  }
  login = async (value: any) => {
    return await request
      .get<API.Response<User>>('/shopapi/lj/user/loginPcByPhone', { params: value })
      .then((response) => {
        const res = response.data
        if (!res.code && res.data) {
          setToken(res.data.token)
          setUser(res.data)
          runInAction(() => {
            this.user = res.data
            this.token = res.data.token
          })
        }
        return res
      })
  }
  senMsg = async (phone: string) => {
    return await request.get<API.Response>('/shopapi/lj/user/senMsg', { params: { phone } })
  }
  logout = () => {
    setUser(null)
    setToken('')
    this.user = null
    this.token = ''
  }
}
