import axios from 'axios'
import { runInAction } from 'mobx'
import { getItem, setItem } from './storage'

const request = axios.create({
  baseURL: '/',
  timeout: 10000,
  headers: { clientId: 'wxminipro_lj_pc' },
})

// Add a request interceptor
request.interceptors.request.use(
  function (config) {
    config.headers.token = getItem('TOKEN_KEY')
    // Do something before request is sent
    return config
  },
  async function (error) {
    // Do something with request error
    return await Promise.reject(error)
  },
)

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data && response?.data?.code) {
      if (response?.data?.code === 100099) {
        runInAction(() => {
          window.rootStore.loginStore.open(true)
          window.rootStore.loginStore.token = ''
          setItem('TOKEN_KEY', '')
        })
      } else {
        window.rootStore.AntdBus.message.error(response?.data?.err)
      }
    }
    return response
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return await Promise.reject(error)
  },
)

export default request
