export function getItem(key: string) {
  const _data = window.localStorage.getItem(key)
  if (!_data) return _data
  try {
    const data = JSON.parse(_data)

    return data
  } catch (e) {
    return _data
  }
}

export function setItem(key: string, data: any) {
  const _data = typeof data === 'object' ? JSON.stringify(data) : data
  window.localStorage.setItem(key, _data)
}
