import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { App } from 'antd'

import Router from '@/components/Router'
import config from './config'
import { useRootStore } from '@/store'

const RouteP: React.FC = observer(() => {
  const { message } = App.useApp()
  const { AntdBus } = useRootStore()
  useEffect(() => {
    if (
      AntdBus.message.messageContent &&
      AntdBus.message.messageType &&
      message[AntdBus.message.messageType]
    ) {
      message[AntdBus.message.messageType](AntdBus.message.messageContent)
    }
  }, [AntdBus.message.messageType, AntdBus.message.messageContent])
  return <Router routerConfig={config} />
})
export default React.memo(RouteP)
