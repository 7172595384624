import { makeAutoObservable, runInAction } from 'mobx'
import request from '@/utils/request'

export default class ConfigStore {
  feedbackUrl: string = ''
  merchantSettlementUrl: string = ''
  constructor() {
    makeAutoObservable(this)
    this.getCommonConfig()
  }

  getCommonConfig = async () => {
    return await request
      .get<
        API.Response<{
          feedbackUrl: string
          merchantSettlementUrl: string
        }>
      >('/shopapi/lj/user/common/config')
      .then((response) => {
        const res = response.data
        if (!res.code && res.data) {
          runInAction(() => {
            this.feedbackUrl = res.data.feedbackUrl
            this.merchantSettlementUrl = res.data.merchantSettlementUrl
          })
        }
        return res
      })
  }
}
