import React from 'react'
import { ConfigProvider, App } from 'antd'
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs'

import Route from './route'
import { RootStoreProvider } from '@/store'

const Main: React.FC = () => {
  return (
    <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
      <RootStoreProvider>
        <ConfigProvider
          theme={{
            token: { colorPrimary: '#2F2F2F', colorInfo: '#2F2F2F' },
            components: {
              Button: {
                // 按钮的 box-shadow
                primaryShadow: 'unset',
              },
              Input: {
                activeShadow: 'unset',
                activeBorderColor: '#d9d9d9',
                hoverBorderColor: '#d9d9d9',
              },
              Radio: {
                colorPrimaryActive: 'red',
              },
              Modal: {
                borderRadiusLG: 6,
              },
            },
          }}
        >
          <App>
            <Route />
          </App>
        </ConfigProvider>
      </RootStoreProvider>
    </StyleProvider>
  )
}

export default React.memo(Main)
