import { Modal, Tabs, TabsProps, App, Form } from 'antd'
import { observer } from 'mobx-react'

import { ActiveKey } from '@/store/login'
import { useRootStore } from '@/store'

import LoginForm from './Form'

import styles from './index.module.scss'

export default observer(function LoginModal() {
  const { loginStore } = useRootStore()
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const items: TabsProps['items'] = [
    {
      key: ActiveKey.login,
      label: '账号登录',
      children: (
        <LoginForm
          form={form}
          submitText="登录"
          onSuccess={() => {
            loginStore.open(false)
            message.success('登录成功')
          }}
        />
      ),
    },
    {
      key: ActiveKey.register,
      label: '免费注册',
      children: (
        <LoginForm
          form={form}
          submitText="注册"
          onSuccess={() => {
            loginStore.open(false)
            message.success('注册成功')
          }}
        />
      ),
    },
  ]
  return (
    <Modal
      className={styles['login-model']}
      open={loginStore.loginOpen}
      onCancel={() => {
        loginStore.open(false)
        form.resetFields(['smsCode'])
      }}
      width={370}
      footer={null}
      closeIcon={null}
      destroyOnClose={true}
    >
      <Tabs
        className={styles.tabs}
        activeKey={loginStore.activeKey}
        items={items}
        onChange={(key) => {
          form.resetFields(['smsCode'])
          loginStore.updateActiveKey(key as ActiveKey)
        }}
      ></Tabs>
    </Modal>
  )
})
