import React from 'react'
import styles from './index.module.scss'
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  outProps?: React.HTMLAttributes<HTMLDivElement>
}
const Content: React.FC<Props> = ({ children, outProps, ...props }) => {
  return (
    <div {...(outProps ?? {})} className={`${styles.layout} ${outProps?.className ?? ''}`}>
      <div {...props} className={`${styles.content} ${props.className ?? ''}`}>
        {children}
      </div>
    </div>
  )
}

export default Content
