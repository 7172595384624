import React, { createContext, useContext } from 'react'

import LoginStore from './login'
import ConfigStore from './config'
// 用于antd 组件 的 通讯
import AntdBus from './antdBus'

// 常用变量
export const cityCode = '110000000000'

export const phoneReg: RegExp =
  /^(13[0-9]|14[579]|15[012356789]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/

declare global {
  interface Window {
    rootStore: RootStore
  }
}

class RootStore {
  loginStore: LoginStore
  configStore: ConfigStore
  AntdBus: AntdBus
  constructor() {
    this.loginStore = new LoginStore()
    this.configStore = new ConfigStore()
    this.AntdBus = new AntdBus()
  }
}

const rootStore = new RootStore()
window.rootStore = rootStore
const RootStoreContext = createContext(rootStore)
const Provider = RootStoreContext.Provider

export const RootStoreProvider: React.FC<any> = ({ children }) => {
  return <Provider value={rootStore}>{children}</Provider>
}

export const useRootStore = () => {
  return useContext(RootStoreContext)
}
