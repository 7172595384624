import { useMemo } from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'
export function RouteErrorBoundary() {
  const error: any = useRouteError() as Error
  const isRouteError = useMemo<boolean>(() => isRouteErrorResponse(error), [error])
  return (
    <div style={{ minHeight: 200, textAlign: 'center', lineHeight: '24px', color: '#114742' }}>
      <h1 style={{ fontSize: 30, lineHeight: '40px' }}>Something went wrong</h1>
      <h2 style={{}}>您可以尝试刷新页面，或联系开发人员</h2>
      {isRouteError ? (
        <>
          <h3>路由加载失败 {error.status}</h3>
          <h4>{error.statusText}</h4>
        </>
      ) : (
        <>
          <h3>{error?.message}</h3>
          <div style={{ color: '#aaa' }}>{error?.stack}</div>
        </>
      )}
    </div>
  )
}
