import React, { useMemo } from 'react'
import { Outlet, useNavigate } from 'react-router'
import { Layout, Menu, App } from 'antd'
import { observer } from 'mobx-react'

import LoginModal from '../LoginModal'
import Content from '../Content'

import { useRootStore } from '@/store'

import styles from './index.module.scss'
import logoPng from './images/logo.png'
import locationPng from './images/location.png'
import { ActiveKey } from '@/store/login'

const { Header, Content: LayoutContent, Footer } = Layout

const Page: React.FC = observer(() => {
  const { modal } = App.useApp()
  const navigate = useNavigate()
  const { loginStore } = useRootStore()
  const items = useMemo(() => {
    const res = [
      {
        key: 'location',
        label: (
          <span className={styles.location}>
            <img src={locationPng} alt="地址" />
            北京市
          </span>
        ),
      },
      {
        key: 'login',
        label: <>登录</>,
      },
      {
        key: 'register',
        label: <>免费注册</>,
      },
      {
        key: 'order',
        label: <>我的订单</>,
      },
      {
        key: 'merchantSettlement',
        label: <>商家入驻</>,
      },
    ]
    if (loginStore?.user && loginStore?.user?.token) {
      res[1] = {
        key: 'phone',
        label: <>{(loginStore?.user?.phone ?? '').replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3')}</>,
      }
      res[2] = {
        key: 'logout',
        label: <>登出</>,
      }
    }
    return res
  }, [loginStore.user])
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <LoginModal />
      <Header className={styles.header}>
        <Content className={styles['header-content']}>
          <div
            className={styles.logo}
            onClick={() => {
              navigate('/', { replace: true })
            }}
          >
            <img src={logoPng} alt="礼记" className={styles['logo-png']} />
            <span className={styles['logo-text']}>急你所急的礼品同城急送</span>
          </div>
          <Menu
            className={styles.menu}
            theme="light"
            mode="horizontal"
            items={items}
            onClick={(e) => {
              switch (e.key) {
                case 'login':
                  loginStore.open(true)
                  break
                case 'register':
                  loginStore.open(true, ActiveKey.register)

                  break
                case 'logout':
                  modal.confirm({
                    icon: null,
                    title: '温馨提示',
                    content: '确定要退出登录吗？',
                    okText: '确认',
                    cancelText: '取消',
                    okButtonProps: { type: 'primary' },
                    onOk: () => {
                      loginStore.logout()
                    },
                  })
                  break
                case 'order':
                  navigate('/order', {})
                  break
                case 'merchantSettlement':
                  navigate('/merchantInterpage')
                  break
              }
            }}
          />
        </Content>
      </Header>
      <LayoutContent className={styles['layout-content']}>
        <div className={styles.content}>
          <Outlet />
        </div>
      </LayoutContent>
      <Footer className={styles.footer}>
        北京易送科技有限公司
        <a href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer noopener">
          京ICP备19019490号-3
        </a>
        <a
          rel="noreferrer noopener"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?COLLCC=889263189&recordcode=11010802035374"
        >
          <img src="https://file.ishansong.com/merchant_storage/2020/04/22/2644b9bc-8687-448b-a585-1b27b77e02ef.png" />
          京公网安备11010802035374号
        </a>
      </Footer>
    </Layout>
  )
})

export default Page
